import {
  createSignal,
  createUniqueId,
  type JSX,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";

import type { InputTypeCheckboxProps } from "~/components/Forms/Fields/Base/InputTypesProps";
import { useFormContext } from "~/contexts/FormContext";

export function InputTypeCheckbox(props: InputTypeCheckboxProps) {
  const [, { setValue: formSetValue }] = useFormContext();
  const setValue = props.defaultFormStorage
    ? props.defaultFormStorage
    : formSetValue;

  const isRequired = () => props.required || false;

  // eslint-disable-next-line solid/reactivity
  const [inputHasContent, setInputHasContent] = createSignal(!!props.value);
  const [inputIsFocus, setInputIsFocus] = createSignal(false);
  const [touched, setTouched] = createSignal(false);
  const [blurCount, setBlurCount] = createSignal(0);

  const setHasContent: JSX.EventHandler<HTMLInputElement, Event> = (e) => {
    setInputHasContent(e.currentTarget.value.length > 0);
  };

  // eslint-disable-next-line solid/reactivity
  const id = `${props.name}-${createUniqueId()}`;

  onMount(() => {
    props.validateFn && props.value && props.validateFn(props.value);
    setInputHasContent(!!props.value);
  });

  return (
    <>
      <div
        class={`checkbox-control form-control-${props.name}`}
        classList={{
          "is-focus": inputIsFocus(),
          "has-content": inputHasContent(),
          "in-error": props["aria-invalid"],
          "is-disabled": props.disabled,
        }}
      >
        <div class="form-slot form-checkbox-field">
          <input
            type="checkbox"
            id={id}
            name={props.name}
            required={isRequired()}
            aria-required={isRequired()}
            aria-invalid={props["aria-invalid"] || false}
            aria-errormessage={
              props["aria-invalid"] ? props["aria-errormessage"] : undefined
            }
            value={props.value}
            onFocusIn={() => setInputIsFocus(true)}
            onFocusOut={() => setInputIsFocus(false)}
            onChange={(event) => {
              setTouched(true);
              setHasContent(event);
            }}
            onInput={(event) => {
              setTouched(true);
              if (props.onInput) {
                props.onInput(event);
              }
              if (props.validateFn && touched() && blurCount() > 0) {
                props.validateFn(event.currentTarget.value);
              }
              setValue!(props.name, event.currentTarget.checked ? "1" : "0");
            }}
            onBlur={(event) => {
              setTouched(true);
              setBlurCount((prev) => prev + 1);
              if (props.onBlur) {
                props.onBlur(event);
              }
              if (props.validateFn && touched()) {
                props.validateFn(event.currentTarget.value);
              }
            }}
          />

          <Switch>
            <Match when={props.labelJsxElement}>
              <label for={id}>
                <span>{props.labelJsxElement}</span>
                <Show when={isRequired()} keyed>
                  {" "}
                  *
                </Show>
              </label>
            </Match>
            <Match when={props.label}>
              <label for={id}>
                <span innerHTML={props.label} />
                <Show when={isRequired()} keyed>
                  {" "}
                  *
                </Show>
              </label>
            </Match>
          </Switch>
        </div>
        <Show when={props.help} keyed>
          <div class="field-help">{props.help}</div>
        </Show>
      </div>
    </>
  );
}
