import { Show, splitProps } from "solid-js";

import type { CheckboxRgpd } from "~/components/Forms/Fields/Base/InputTypesProps";
import { InputTypeCheckbox } from "~/components/Forms/Fields/Base/InputTypeCheckbox";
import { optional, picklist } from "valibot";
import ToolTipHTML from "~/components/shared/ToolTipHTML";

const LABEL =
  "Je suis intéressé(e) par les offres et services personnalisés de l’ensemble des marques du Groupe ALTAREA";
const IS_REQUIRED_BY_DEFAULT = false;

export function OptInCogedimSchema() {
  return {
    optin_cogedim: optional(picklist(["0", "1"]), "0"),
  };
}

export function OptInCogedim(props: CheckboxRgpd & { showBrands?: boolean }) {
  const [local, others] = splitProps(props, ["label", "help", "required"]);

  return (
    <>
      <div class="optin-cogedim">
        <Show
          when={props.showBrands}
          fallback={
            <InputTypeCheckbox
              label={local.label || LABEL}
              help={local.help}
              required={
                local.required !== undefined
                  ? local.required
                  : IS_REQUIRED_BY_DEFAULT
              }
              value="1"
              {...others}
            />
          }
        >
          <InputTypeCheckbox
            labelJsxElement={
              <>
                Je suis intéressé(e) par les offres et services personnalisés de
                l’ensemble{" "}
                <ToolTipHTML
                  tip={
                    <ul>
                      <li>COGEDIM – Promoteur immobilier</li>
                      <li>Woodeum x Pitch Immo – Promoteur immobilier</li>
                      <li>Histoire & Patrimoine – Promoteur immobilier</li>
                      <li>
                        Altarea Solution Services et ses sous-marques Altarea
                        Gestion Immobilière, Altarea Patrimoine et Altarea
                        Solution Financement – Conseil en Patrimoine, courtage
                        en financement, gestion locative, syndic de copropriété,
                        services d’accompagnement aux investisseurs particuliers
                      </li>
                      <li>
                        Altarea Commerce – Administration de centres commerciaux
                      </li>
                      <li>
                        Nohée – Administration de résidences services seniors
                      </li>
                    </ul>
                  }
                >
                  <span class="underline">des marques du Groupe ALTAREA</span>
                </ToolTipHTML>
              </>
            }
            help={local.help}
            required={
              local.required !== undefined
                ? local.required
                : IS_REQUIRED_BY_DEFAULT
            }
            value="1"
            {...others}
          />
        </Show>
      </div>
    </>
  );
}
