import { For } from "solid-js";
import type { SubmissionFailed } from "../api";

export function Errors(props: { issues: SubmissionFailed["issues"] }) {
  return (
    <ul class="error-message" role="alert" id="error-message">
      <For each={props.issues}>{(issue) => <li>{issue.message}</li>}</For>
    </ul>
  );
}
